html {
    height: 100%;
}

body.login { 
    background: #fff;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    position: relative;
    z-index: -10;
} 

.login h1 a {
	background: url('../images/logo.png') no-repeat top center; 
	width: 205px;
	height: 79px;
	text-indent: -9999px;
	overflow: hidden;
	display: block;
	position: relative;
	z-index: 10;
}

#loginform {
    background: #35845a;
    position: relative;
	z-index: 10;
}

.login label {
	color: #fff !important;
} 

form {
	margin-left: 8px;
	padding: 26px 24px 46px;
	font-weight: normal;
	border: 0 none;
	border-radius: 0;
	box-shadow: none !important;
}

.login form .input, .login form input[type="checkbox"], .login input[type="text"] {
    background: #fff;
    border: 0 none;
}

input[type="radio"], input[type="checkbox"] {
	box-shadow: none !important;
}

#wp-submit {
    background: #35845a;
    border-radius: 0;
    border-color: #fff;
    border-width: 4px;
    height: 40px;
    padding: 0 12px;
    text-transform: uppercase;
    font-weight: 700;
    box-shadow: none !important;
    text-shadow: none !important;
}

.login #nav,
.login #backtoblog {
    position: relative;
	z-index: 10;
	color: #35845a;
	text-align: center;
	a {
        color: #35845a;
        
        &:hover {
            color: #35845a;
        }
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (min--moz-device-pixel-ratio: 1.5),
       only screen and (min-device-pixel-ratio: 1.5) {

    .login h1 a {
        background: url('../images/logo@2x.png') no-repeat top center;
        background-size: 205px 79px;
    }

} 

